/** @format */

import React, { useContext } from 'react';
import classNames from 'classnames';
import { CircleCheckIcon, CircleIcon } from 'lucide-react';
import { TrialContext, type TrialPlan } from './TrialContext';

const plans = [
  {
    id: 'standard',
    name: 'Standard Plan Trial',
    cost: '$49',
    description:
      'Unlimited storyboarding for individual creatives and small teams.',
  },
  {
    id: 'workflow',
    name: 'Workflow Plan Trial',
    cost: '$99',
    description:
      'Everything in Standard plus sharing, feedback, and team collaboration.',
  },
];

export const TrialPlans: React.FC = () => {
  const { currentPlan, setCurrentPlan } = useContext(TrialContext);

  return (
    <div className="space-y-4">
      {plans.map((plan) => (
        <div
          key={plan.id}
          className={classNames(
            'flex items-center px-4 pt-3 pb-4 border-2 cursor-pointer rounded-xl',
            currentPlan === plan.id ? 'border-brand-pink' : 'border-border',
          )}
          onClick={() => setCurrentPlan(plan.id as TrialPlan)}
        >
          <div className="hidden sm:flex justify-center items-center flex-shrink-0 text-brand-pink mr-3.5">
            {currentPlan === plan.id ? (
              <CircleCheckIcon className="w-5 h-5 text-brand-pink" />
            ) : (
              <CircleIcon className="w-5 h-5 text-type-disabled" />
            )}
          </div>
          <div className="flex-auto">
            <div
              className={classNames(
                'text-base font-semibold',
                currentPlan === plan.id ? '' : 'text-type-subdued',
              )}
            >
              {plan.name}
            </div>
            <div className="text-sm text-type-subdued">{plan.description}</div>
          </div>
          <div className="flex flex-col justify-start flex-shrink-0 hidden ml-5 -mt-1 text-right">
            <div
              className={classNames(
                'text-sm',
                currentPlan === plan.id ? '' : 'text-type-subdued',
              )}
            >{`Free for 7 days`}</div>
            <div
              className={classNames(
                'text-xs ',
                currentPlan === plan.id
                  ? 'text-type-subdued'
                  : 'text-type-disabled',
              )}
            >{`then ${plan.cost}/mo`}</div>
            <div className="text-xs opacity-0 text-type-subdued">{`.`}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
